/**
 * Calculates the difference in minutes between two date-time values.
 * @param {string} startHour - The start date-time in ISO format (e.g. "2023-02-15T10:00:00").
 * @param {string} endHour - The end date-time in ISO format (e.g. "2023-02-15T11:30:00").
 * @returns {number} - The difference in minutes between startHour and endHour.
 */
export const getMinuteDifference = (startHour, endHour) => {
  // Calculate the absolute difference in seconds between the two dates, and then convert to minutes.
  return Math.abs(((new Date(endHour) - new Date(startHour)) / 1000) / 60)
}

/**
 * Groups schedules into arrays where each array contains schedules
 * that do not overlap in time.
 *
 * @param {Array} schedules - An array of schedule objects with `startHour`
 * and `endHour` properties.
 * @returns {Array} - An array of arrays, each containing non-overlapping schedules.
 */
export const groupNonOverlappingSchedules = (schedules) => {
  const sortedSchedules = schedules.slice().sort((a, b) => a.startHour - b.startHour);

  const groups = [];

  for (let i = 0; i < sortedSchedules.length; i++) {
    const current = sortedSchedules[i];
    const currentEventId = current?.data?.event?.document?.id || '';
    let added = false;

    for (let j = 0; j < groups.length; j++) {
      const group = groups[j];
      const lastInGroup = group[group.length - 1];

      const lastEventId = lastInGroup?.data?.event?.document?.id || '';

      if (
        lastEventId === currentEventId &&
        current.startHour >= lastInGroup.endHour
      ) {
        group.push(current);
        added = true;
        break;
      }
    }

    if (!added) {
      for (let j = 0; j < groups.length; j++) {
        const group = groups[j];
        const lastInGroup = group[group.length - 1];

        if (current.startHour >= lastInGroup.endHour) {
          group.push(current);
          added = true;
          break;
        }
      }
    }

    if (!added) {
      groups.push([current]);
    }
  }

  return groups;
};


/**
 * Take a range of hour and an offset to return the time between this range.
 * @param {string,Date} startHour Will be converted to new Date()
 * @param {string,Date} endHour String | Will be converted to new Date()
 * @param {int} step in Minute
 * @param offset
 * @returns {number[]}
 * Return an array of all the hours between startHour and endHour in milliseconds,
 * incremented by the step
 */
export const calculateHourRange = (startHour, endHour, step = 15, offset = 4) => {
  const startLoopHour = new Date(new Date(startHour).getTime() + offset * 60 * 60 * 1000)
  const endLoopHour = new Date(endHour)
  let loop = startLoopHour
  const dateRange = [startLoopHour.getTime()]
  while (loop < endLoopHour) {
    const newDate = loop.setTime(loop.getTime() + step * 60 * 1000)
    dateRange.push(newDate)
    loop = new Date(newDate)
  }
  return dateRange
}
