import React, { useContext, useEffect, useState } from 'react'
import { FilterContext } from './Providers/FilterProvider'
import { AuthContext } from '../Customer/Providers/CustomerAuthProvider'
import { NavContext } from '../Customer/Providers/CustomerNavProvider'
import scrollToElement from '../../../utils/scrollToElement'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { FILTER_ACTIVITY, FILTER_SELECTION, FILTER_FREE, FILTER_ALPHABETICAL } from './Calendar/Constants'
import ColorButton from '../../Ui/ColorButton'
import { StaticImage } from 'gatsby-plugin-image'
import { Search } from 'lucide-react';

const FilterButton = ({ text, className, innerClassName, onClick }) => {
  return <>
    <div onClick={onClick} className={`font-obviously font-black uppercase cursor-pointer text-white !text-lg hover:!underline ${className}`}>
      <p className={`${innerClassName}`}>{text}</p>
    </div>
  </>
}

const ProgrammationFilter = () => {
  const location = useLocation()

  const calendarFilter = 'calendrier'
  const allFilter = 'tous'
  const alphabeticalFilter = FILTER_ALPHABETICAL
  const maSelectionFilter = FILTER_SELECTION
  const gratuitFilter = FILTER_FREE
  const miscActivities = FILTER_ACTIVITY

  const { filter, setFilter, searchTerm, setSearchTerm, calendar, setCalendar, bottomFilterRef } = useContext(FilterContext)
  const { setIsPanelOpen } = useContext(NavContext)
  const { user, loading } = useContext(AuthContext)

  // Switch to calendar view if hash of the url contain #calendrier
  // Switch on filter according to url "filtre" param
  useEffect(() => {
    // get filtre url param
    const urlFilter = new URLSearchParams(location.search).get('filtre')

    // Activate filter if not already activated and user not loading
    if (urlFilter && urlFilter !== filter && !loading) {
      toggleFilter(urlFilter)
    }

    // set view to calendar if hash = calendarFilter
    if (location.hash === `#${calendarFilter}`) { setCalendar(true) }
  }, [location, loading, user])

  const toggleFilter = (newFilter) => {
    // Set default hash to allFilter to avoid page refresh on navigate
    const hash = location.hash === `#${calendarFilter}` ? `#${calendarFilter}` : `#${allFilter}`

    if (newFilter === maSelectionFilter && !user && !loading) {
      setIsPanelOpen(true)
    } else if (newFilter === filter) {
      setFilter(null)
      // Remove all params
      navigate(`${hash}`, { replace: true })
    } else {
      setFilter(newFilter)
      // Remove filtre param using newFilter function parameter
      navigate(`?filtre=${encodeURIComponent(newFilter)}${hash}`, { replace: true })
      scrollToElement(bottomFilterRef && bottomFilterRef.current)
    }
  }

  return (
    <div className="text-center max-w-[1500px] mx-auto relative md:pt-12 pt-0">

      {/* <StaticImage
        src="../../../images/wolf/ufo2.png"
        alt="Illustration d'OVNI"
        placeholder='blurred'
        className="absolute top-[10px] left-[250px] w-[100px] h-auto xl:block hidden"/> */}

      <section className="mx-4 md:mx-20 lg:mx-40 py-12 lg:pb-0 lg:pt-0 justify-center md:px-18 px-9">
        <div className="md:w-3/4 w-full md:flex mb-6 text-center mx-auto h-18">
            <div className={`${(process.env.GATSBY_IS_BORNE === 'false' || process.env.GATSBY_IS_BORNE === false) ? 'hidden' : ''} md:w-1/6`}></div>
            <div className="md:w-1/3 w-full px-2 md:my-0 my-2">
                <ColorButton text="Tous les artistes"
                            className={`w-full h-full md:mb-0 mb-6 ${!calendar ? 'toggle-filter-toggled' : ''}`}
                            isYellow={true}
                            onClick={() => {
                              scrollToElement(bottomFilterRef && bottomFilterRef.current)
                              setCalendar(false)
                              navigate(`${location.search}#${allFilter}`, { replace: true })
                            }}
                />
            </div>
            <div className="md:w-1/3 w-full px-2 md:my-0 my-2">
                <ColorButton text="Horaire par jour"
                            className={`w-full h-full md:mb-0 mb-6 ${!calendar ? 'toggle-filter-toggled' : ''}`}
                            isPink={true}
                            onClick={() => {
                              scrollToElement(bottomFilterRef && bottomFilterRef.current)
                              setCalendar(true)
                              navigate(`${location.search}#${calendarFilter}`, { replace: true })
                            }}
                />
            </div>
            <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''} md:w-1/3 w-full px-2 md:my-0 my-2`}>
                <ColorButton className="w-full h-full"
                            text="Consulter l'horaire en PDF"
                            isBlue={true}
                            externalLink={true}
                            href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/Z_Zq6XdAxsiBwe-G_FEST-2025_Horaire_2025-04-07.pdf"/>
            </div>
        </div>

        <div className="w-full md:flex text-center justify-center mx-auto items-center">
            <div className="md:w-2/12 w-full px-2 my-0">
                <FilterButton onClick={() => toggleFilter(alphabeticalFilter)}
                        className={`w-full h-full !px-0 md:!py-8 !py-4 ${filter === alphabeticalFilter ? '!underline' : '!no-underline'}`}
                        text={` ${alphabeticalFilter} `}/>
            </div>
            <div className="md:w-3/12 w-full px-2 my-0">
                <FilterButton onClick={() => toggleFilter(maSelectionFilter)}
                        className={`w-full h-full !px-0 md:!py-8 !py-4 ${filter === maSelectionFilter ? '!underline' : '!no-underline'}`}
                        text={` ${maSelectionFilter} `}/>
            </div>
            <div className="md:w-3/12 w-full px-2 my-0">
                <FilterButton onClick={() => toggleFilter(miscActivities)}
                        className={`w-full h-full !px-0 md:!py-8 !py-4 ${filter === miscActivities ? '!underline' : '!no-underline'}`}
                        text={` ${miscActivities} `}/>
            </div>
            <div className="md:w-3/12 w-full px-2 my-0">
                <FilterButton onClick={() => toggleFilter(gratuitFilter)}
                        className={`w-full h-full !px-0 md:!py-8 !py-4 ${filter === gratuitFilter ? '!underline' : '!no-underline'}`}
                        text={` ${gratuitFilter} `}/>
            </div>
            <div className="md:w-3/12 w-full mx-auto relative">
              <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="px-4 py-2 bg-white-opacity20 rounded-3xl w-full text-white font-obviously font-medium !normal-case" />
                    {searchTerm === "" && (
                      <Search className="absolute right-4 top-1/2 -translate-y-1/2 text-white" size={20} />
                    )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default ProgrammationFilter